import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { navigate } from 'gatsby';
import { useAuthContext } from '../../context/AuthProvider';

const LOGIN = gql`
	mutation Login($login: String!, $password: String!) {
		loginWithCookies(input: { login: $login, password: $password }) {
			status
		}
	}
`;

const LOGOUT = gql`
	mutation Logout {
		logout(input: {}) {
			status
		}
	}
`;

const LoginPage = ({ location }) => {
  const redirectPath = location.state?.redirect || '/';
  const [loginMutation, { loading }] = useMutation(LOGIN);
  const [logoutMutation] = useMutation(LOGOUT);
  const { setIsLoggedIn, isLoggedIn, userData, isUserLoading } = useAuthContext();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();

    if (loading) return;

    loginMutation({ variables: { login: email, password } })
      .then((res) => {
        console.log(res);
        setIsLoggedIn(true);
        navigate(redirectPath);
      }).catch((error) => {
        const incorrectDetails = error.message.toLowerCase() === 'invalid_username' || error.message.toLowerCase() === 'invalid_email' || error.message.toLowerCase() === 'incorrect_password';
        if (incorrectDetails) {
          setErrorMessage('Incorrect email or password');
        } else {
          setErrorMessage(error.message);
        }
      });
  };

  const handleLogout = (e) => {
    e.preventDefault();
    logoutMutation().then(() => {
      setIsLoggedIn(false);
    });
  };

  if (isUserLoading) return (<div>Loading...</div>);

  if (isLoggedIn) {
    return (
      <div id="login" className='vh-100 d-flex justify-content-center align-items-center'>
        <form onSubmit={handleLogout} className='p-3 d-flex flex-column col-12 col-md-4 align-items-center'>
          <p className='uppercasebold'>You're logged in</p>
          <button type="submit" className="btn mt-3 uppercasebold">Logout</button>
        </form>
      </div>
    );
  }

  return (
    <div id="login" className='vh-100 d-flex justify-content-center align-items-center'>
      <form onSubmit={handleLogin} className='p-3 d-flex flex-column col-12 col-md-4'>
        <h1>Login</h1>
        <div className="form-group">
          <input
            type="email"
            className="form-control"
            id="email-input"
            aria-describedby="email"
            placeholder='E-mail'
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group mt-1">
          <input
            type="password"
            className="form-control"
            id="password-input"
            placeholder='Password'
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="btn mt-3 uppercasebold">Login</button>

        {/* Loading */}
        {loading && (
          <div className="alert mt-3 p-1 text-center" role="alert">
            Loading...
          </div>
        )}

        {/* Error message container */}
        {errorMessage && (
          <div className="alert mt-3 p-1 text-center" role="alert">
            {errorMessage.replace(/_/g, ' ')}
          </div>
        )}
      </form>
    </div>
  );
};

export default LoginPage;
